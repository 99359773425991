<script setup lang="ts">
import {ref, onMounted, watch} from 'vue';
import {useSearchStore} from '@/js/stores/useSearchStore';
import MagnifyGlass from '/src/svgs/MagnifyGlass.svg?raw';
import {debounce} from 'lodash';

const searchStore = useSearchStore();
const showAutocomplete = ref(false);
const isSearchResultsPage = ref(false);
const recaptchaKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

const checkSearchPage = () => {
    isSearchResultsPage.value = window.location.pathname.includes('/search');
};

const debouncedFetchAutocompleteResults = debounce(async () => {
    if (searchStore.query.length < 3) {
        showAutocomplete.value = false;
        searchStore.autocompleteResults = [];
        return;
    }

    await searchStore.fetchAutocompleteResults();
    showAutocomplete.value = searchStore.autocompleteResults.length > 0;
}, 400);

let skipNextWatch = true;

watch(() => searchStore.query, () => {
    if (skipNextWatch) {
        skipNextWatch = false;
        return;
    }

    if (isSearchResultsPage.value && searchStore.query.trim() === '') return;

    debouncedFetchAutocompleteResults();
});

onMounted(() => {
    checkSearchPage();

    if (isSearchResultsPage.value) {
        const params = new URLSearchParams(window.location.search);
        searchStore.query = params.get('q') || '';
        searchStore.selectedTopic = params.get('topic') || '';
        searchStore.selectedResourceType = params.get('resourceType') || '';
        searchStore.page = parseInt(params.get('page') || '1');
    }
});



const handleFocus = () => {
    if (searchStore.autocompleteResults.length > 0) {
        showAutocomplete.value = true;
    }
};

const hideAutocomplete = () => {
    setTimeout(() => {
        showAutocomplete.value = false;
    }, 200);
};

const performSearch = () => {
    const params = new URLSearchParams({
        q: searchStore.query || '',
        topic: searchStore.selectedTopic || '',
        resourceType: searchStore.selectedResourceType || '',
        page: '1'
    });

    window.location.href = `/search?${params.toString()}`;
};

const getPreferredHtml = (result: any): string => {
    if (result.teaser) return result.teaser;
    if (result.autoDescription) return result.autoDescription;

    if (result.copy && result.title) {
        const pattern = new RegExp(`<p><strong>${result.title}</strong> -\\s*`, 'i');
        return result.copy.replace(pattern, '<p>');
    }

    return '';
};

</script>

<template>
    <div class="header-search w-full">
        <div class="header-form form form--header">
            <!-- Search Input -->
            <input
                id="autocomplete-input"
                v-model="searchStore.query"
                placeholder="Search for..."
                aria-label="Search for..."
                class="form-field flex-grow w-full !rounded-l-md"
                @keyup.enter="performSearch"
                @focus="handleFocus"
                @blur="hideAutocomplete"
            />

            <!-- Autocomplete Results -->
            <div
                v-if="showAutocomplete && searchStore.autocompleteResults.length"
                class="absolute left-0 top-full w-full bg-white z-50 shadow-lg border border-gray-2 rounded-md mt-2 max-h-[32vh] overflow-y-auto"
            >
                <ul>
                    <li
                        v-for="result in searchStore.autocompleteResults"
                        :key="result.objectID"
                        class="px-4 py-3 text-black cursor-default transition duration-200 flex flex-col border-b border-gray-2 last:border-none"
                    >
                        <div
                            v-if="result.sectionHandle === 'glossary'"
                            class="bg-blue-50"
                        >
                            <p class="text-sm uppercase font-bold text-blue-11 mb-1">
                                Definition
                            </p>
                            <p class="font-semibold text-base mb-2 text-black">
                                {{ result.title }}
                            </p>
                            <div v-html="getPreferredHtml(result)" class="text-base text-black  prose prose-sm max-w-none"></div>
                        </div>
                        <div v-else>
                        <a :href="result.url"
                           class="font-semibold text-base hover:underline hover:text-blue-default">
                            {{ result.title }}
                        </a>
                        <div
                            v-if="getPreferredHtml(result)"
                            v-html="getPreferredHtml(result)"
                            class="text-sm text-gray-600 cursor-text"
                        ></div>

                        <div class="flex flex-wrap gap-2 mt-1"
                             v-if="result.relatedResourceTypes?.length || result.relatedTopics?.length">
                            <div
                                v-for="resourceType in result.relatedResourceTypes ?? []"
                                :key="resourceType"
                                class="flex-initial px-2 py-1 inline-flex items-center justify-start gap-2 rounded-md uppercase font-bold bg-orange-5 text-white cursor-text"
                            >
                                <small class="tag">{{ resourceType }}</small>
                            </div>
                            <div
                                v-for="topic in result.relatedTopics ?? []"
                                :key="topic"
                                class="flex-initial px-2 py-1 inline-flex items-center justify-start gap-2 rounded-md uppercase font-bold bg-orange-5 text-white cursor-text"
                            >
                                <small class="tag">{{ topic }}</small>
                            </div>
                        </div>
                        </div>
                    </li>
                </ul>
            </div>

            <!-- Dropdowns -->
            <select v-model="searchStore.selectedTopic"
                    class="form-field w-full xl:w-1/4">
                <option value="">Topic</option>
                <option v-for="topic in searchStore.searchTopics"
                        :key="topic.id" :value="topic.title">
                    {{ topic.title }}
                </option>
            </select>

            <select v-model="searchStore.selectedResourceType"
                    class="form-field w-full xl:w-1/4">
                <option value="">Resource Type</option>
                <option v-for="resource in searchStore.searchResourceTypes"
                        :key="resource.id" :value="resource.title">
                    {{ resource.title }}
                </option>
            </select>

            <!-- Search Button -->
            <div class="relative">
                <button @click="performSearch"
                        class="g-recaptcha form-button max-xl:w-full rounded-r-md disabled:opacity-75">
                    <div v-html="MagnifyGlass"
                         class="w-6 h-6 xl:w-[1.25rem] xl:h-[1.25rem]"></div>
                    <span class="xl:sr-only">Search</span>
                </button>
            </div>
        </div>
    </div>
</template>


